import type { mastodon } from '#types'

export function getStatusRoute(status: mastodon.v1.Status) {
  return useRouter().resolve({
    name: 'status',
    params: {
      account: toHandle(parseHandle(status.account.acct)),
      status: status.id,
    },
  })
}

export function getStatusComposeRoute(status: mastodon.v1.Status) {
  return useRouter().resolve({
    name: 'status-compose',
    params: {
      account: toHandle(parseHandle(status.account.acct)),
      status: status.id,
    },
  })
}

export function getComposeRoute(account: mastodon.v1.Account) {
  return useRouter().resolve({
    name: 'compose',
    params: {
      account: toHandle(parseHandle(account.acct)),
    },
  })
}

export function getAccountRoute(account: mastodon.v1.Account) {
  return useRouter().resolve({
    name: 'account-index',
    params: {
      // server: currentServer.value,
      account: toHandle(parseHandle(account.acct)),
    },
  })
}

export function getAccountFollowingRoute(account: mastodon.v1.Account) {
  return useRouter().resolve({
    name: 'account-following',
    params: {
      // server: currentServer.value,
      account: `@${account.acct}`,
    },
  })
}

export function getAccountFollowersRoute(account: mastodon.v1.Account) {
  return useRouter().resolve({
    name: 'account-followers',
    params: {
      // server: currentServer.value,
      account: `@${account.acct}`,
    },
  })
}

export function getTagRoute(id: string) {
  return useRouter().resolve({
    name: 'tags-id',
    params: {
      // server: currentServer.value,
      id,
    },
  })
}
