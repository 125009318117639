import type { mastodon } from '#types'

export type Handle = {
  username: string
  domain?: string
}

// Parse a "handle" in the form: `[@] <local-part> '@' <domain>`
export function parseHandle(acct: string): Handle {
  if (acct.startsWith('@')) {
    acct = acct.substring(1)
  }
  const [username, domain] = decodeURIComponent(acct).split('@', 2)
  return {
    username,
    domain: useRuntimeConfig().public.domain === domain ? undefined : domain
  }
}

export function toHandle({ username, domain }: Handle): string {
  return `${username}${domain ? '@' : ''}${domain ?? ''}`
}

export function toFullHandle(acct: Handle | string): string {
  const handle = typeof acct === 'string' ? parseHandle(acct) : acct
  const domain = handle.domain ?? useRuntimeConfig().public.domain
  return `${handle.username}${domain ? '@' : ''}${domain ?? ''}`
}

export function getDisplayName(account: mastodon.v1.Account, options?: { rich?: boolean }) {
  const displayName = account.displayName || account.username || account.acct || ''
  if (options?.rich) {
    return displayName
  }
  return displayName.replace(/:([\w-]+?):/g, '')
}

export function useSelfAccount(user: MaybeRefOrGetter<mastodon.v1.Account | null | undefined>) {
  return computed(() => isSelfAccount(user))
}

export function isSelfAccount(user: MaybeRefOrGetter<mastodon.v1.Account | null | undefined>) {
  const userId = resolveUnref(user)?.id
  const accountId = useAuth().account.value?.id
  return userId && accountId && compareHandle(userId, accountId)
}

export function compareHandle(a: string | Handle, b: string | Handle) {
  const aHandle = typeof a === 'string' ? parseHandle(a) : a
  const bHandle = typeof b === 'string' ? parseHandle(b) : b
  const aDomain = aHandle.domain ?? useRuntimeConfig().public.domain
  const bDomain = bHandle.domain ?? useRuntimeConfig().public.domain
  return aDomain === bDomain && aHandle.username === bHandle.username
}
